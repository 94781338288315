<template>
  <b-row>
    <b-col>
      <b-card title="Actualizar permiso">
        <b-row>
          <b-col md="6">
            <div class="form-group">
              <label>Permiso</label>
              <input
                v-model="form.name"
                type="text"
                class="form-control form-control-sm"
                placeholder="Component"
              />
            </div>
          </b-col>
          <b-col md="6">
            <div class="form-group">
              <label>Nombre amigable</label>
              <input
                v-model="form.guard_name"
                @keyup.enter="update()"
                type="text"
                class="form-control form-control-sm"
                placeholder="Referencia"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <router-link
                :to="{ name: 'permissions' }"
                class="btn btn-sm btn-circle btn-link mr-3"
              >
                <i class="la la-reply"></i> Regresar
              </router-link>
              <button
                class="btn btn-sm btn-circle btn-success"
                @click="update()"
                v-if="can('update-permission')"
              >
                <i class="flaticon-edit-1"></i>
                {{ loading ? "Cargando..." : "Actualizar" }}
              </button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import can from "@/permission";
export default {
  data() {
    return {
      id: this.$route.params.id,
      show: false,
      loading: false,
      form: {
        name: null,
        guard_name: null,
      },
    };
  },
  mounted() {
    this.loadPermission();
  },
  methods: {
    can: can,
    reset() {
      this.form = {
        name: null,
        guard_name: null,
      };
    },
    loadPermission() {
      axios
        .get(`/permissions/${this.id}`)
        .then((res) => {
          const [data] = res.data;
          this.form = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    update() {
      if (this.form.name && this.form.guard_name) {
        this.loading = true;

        axios
          .put(`permissions/${this.id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.$swal.fire({
                title: "Información",
                text: "Se ha actualizado el registro",
                icon: "success",
              });
              this.loading = false;
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
        // code
      } else {
        toastr.info("Asegurese de ingresar un correo válido");
      }
    },
  },
};
</script>
